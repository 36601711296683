.main-footer{
    background-color: #333;
    color: #fff;
    text-align: center;
    padding-top: 50px;
    font-size: 2rem;
    padding-bottom: 50px;
}
.footer {
   
    padding: 50px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
  
  .footer-section {
    margin: 20px;
    flex: 1 1 200px;
  }
  
  .footer-icon {
    font-size: 2rem;
    color: #ee3537;
    margin-bottom: 10px;
  }
  
  .footer h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .footer p {
    font-size: 1rem;
    margin: 5px 0;
  }
  