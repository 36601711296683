/* Header.css */

.header {
  width: 100%;
  height: calc(100vh); /* Adjust height as needed */
  overflow: hidden;
  position: relative;
}

/* Example CSS */

.slick-slide {
    position: relative;
  }
  
  .slick-slide img {
    width: 100%;
    height: 100vh; /* Adjust height to match .header */
    object-fit: cover;
    opacity: 0.7; /* Decrease image opacity */
  }
  
  .slick-slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Black with 50% opacity */
  }
  
  

.slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.slide-content {
  position: absolute;
  top: 20%;
  left: 15%;
  transform: translate(-20%, -20%);

  text-align: left;
}

.slide-content h2 {
  font-size: 4rem;
  margin-bottom: 1rem;
  color: #201f70;
  /* stroke: 1px white; */
  text-shadow: 
  -1px -1px 0 rgb(255, 255, 255),  
   1px -1px 0 rgb(58, 9, 235),
  -1px  1px 0 black,
   1px  1px 0 black; /* Outline color */
}

.slide-content p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #fff;
}

.slide-content button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #ee3537;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
}

.slide-content button:hover {
  background-color: #ee3537;
  transform: scale(103%);
  color: white;
  
}

@media (max-width: 768px) {
  .slide-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
