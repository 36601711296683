.container {
    padding: 20px;
  }
  
  .heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .paragraph {
    text-align: center;
    margin-bottom: 40px;
    font-size: 1.2rem;
  }
  
  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .card {
    flex: 1 1 calc(33.333% - 20px);
    margin: 10px;
    height: 400px;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .text {
    z-index: 2;
    font-size: 2rem;
    font-weight: 600;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .card {
      flex: 1 1 100%;
    }
  }
  


  .container {
    padding: 20px;
  }
  
  .heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .paragraph {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .tab-container {
    margin: 40px;
  }
  
  .tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .tab {
    flex: 1;
    padding: 10px;
    margin: 5px;
    background-color: #f1f1f1;
    border: none;
    cursor: pointer;
    text-align: center;
  }
  
  .tab.active {
    background-color: #201f70;
    color: white;
  }
  
  .tab-content {
    padding: 20px;
    background-color: #f1f1f1;
  }
  
  @media (max-width: 768px) {
    .tabs {
      flex-direction: column;
    }
    
    .tab {
      width: 100%;
    }
  }
  


  /* Services.css */

.services {
  padding: 100px 20px;
  text-align: center;
}

.services-title {
  font-size: 2.5rem;
  margin-bottom: 50px;


}

.services-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.services-card {
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px #0000001A;

  padding: 20px;
  width: 300px;
  transition: transform 0.3s ease;
  text-align: center;


}


.services-title::after {
    content: '';
    display: block;
    width: 100px; /* Adjust width as needed */
    height: 4px; /* Adjust height as needed */
    background-color: #201f70; /* Underline color */
    margin: 1px auto 40px; /* Adjust spacing as needed */
    border-radius: 2px; /* Rounded edges */
  }


.services-icon {
  font-size: 3rem;
  color: #000000;
  margin-bottom: 10px;
}

.services-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.services-card p {
  font-size: 1rem;
  color: #666;
}
