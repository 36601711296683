.client-trust-container {
  text-align: center;
  padding: 50px 20px;
  background-color: #201f70;
  
}

.client-trust-container h1 {
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: white;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.review-form {
  width: 40%;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-form h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  margin-top: -5px;
}

.review-form form {
  display: flex;
  flex-direction: column;
}

.review-form input,
.review-form textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.review-form button {
  padding: 10px 20px;
  border: none;
  background-color: #333;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.review-form button:hover {
  background-color: #555;
}

.reviews {
  width: 55%;
}

.review-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.review-card img.avatar {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.review-card h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.review-card .rating {
  display: flex;
  margin-bottom: 10px;
}

.review-card .rating span {
  color: #201f70; /* Gold color for stars */
  font-size: 1.5rem;
}

.review-card p {
  font-size: 1rem;
  color: #666;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .reviews, .review-form {
    width: 90%;
    margin-bottom: 20px;
  }
}
