.study {
  padding: 50px 20px;
  text-align: center;
}

.study-title {
  font-size: 2.5rem;
  margin-bottom: 50px;
}

.study-description {
  font-size: 1.5rem;
  margin-left: 10%;
  margin-right: 10%;
}

.study-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.study-card {
  position: relative;
  /* background-image: url('./img/gratuade-visa.jpg');  */
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px #0000001A;
  padding: 20px;
  width: 300px;
  transition: transform 0.3s ease;
  text-align: center;
  overflow: hidden; /* Ensure the overlay doesn't spill out */
}

.study-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  border-radius: 10px;
  z-index: 1; /* Ensure the overlay is above the background image */
}

.study-card * {
  position: relative;
  z-index: 2; /* Ensure the content is above the overlay */
}

.study-card1 {
  background-image: url('./img/student-visa.jpg'); 
}
.study-card2 {
  background-image: url('./img/student-dependent.jpg'); 
}
.study-card3 {
  background-image: url('./img/gratuade-visa.jpg'); 
}
.study-card4 {
  background-image: url('./img/parent-visa.jpg'); 
}
.study-card5 {
  background-image: url('./img/partner-visa.jpg'); 
}
.study-card6 {
  background-image: url('./img/find-the-course.jpg'); 
}

.study-title::after {
  content: '';
  display: block;
  width: 100px;
  height: 4px;
  background-color: #201f70;
  margin: 1px auto 40px;
  border-radius: 2px;
}

.study-icon {
  font-size: 3rem;
  color: #201f70;
  margin-bottom: 10px;
}

.study-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: white;
}

.study-card p {
  font-size: 1rem;
  color: #ffffff;
}


.button{
  background-color: #ee3537;
  color: rgb(255, 255, 255);
  border: none;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 30px;
  font-weight: 500;
  text-decoration: none;
}